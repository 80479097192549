.pop {
  color: #ffffff;
}

.pop-bright {
  color: #d70f0f;
}


.App {
  text-align: center;
  text-transform: uppercase;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  text-transform: uppercase;
  text-align: center;
  font-size: 5vmin;
}

.App-link a:link {
  color:  #808080;
}

.App-link a:visited {
  color:  #808080;
}

.App-link a:hover {
  color: #E13E2F;
}

.App-link a:active {
  color: #A92123;
}

.App-section {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #8c2122;
}

.App-text {
  font-weight: bold;
  background-color: #ea1717;
  padding: 20px;
  min-width: 80%;
  font-size: calc(10px + 3vmin);
}

@media screen and (min-width: 760px) {
  .App-text {
    font-size: calc(10px + 4vmin);
  }
}

@media screen and (min-width: 1024px) {
  .App-text {
    font-size: calc(10px + 6vmin);
  }
}

.App-map {
  height: 50vmin;
  pointer-events: none;
  width: 90%;
}

.App-moon {
  height: 100vmin;
  pointer-events: none;
  width: 95%;
  margin-left: -20%;
}

.App-world {
  height: 60vmin;
  pointer-events: none;
  width: 100%;
  margin-left: -50%;
}

.App-team {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

.App-team-image {
  object-fit: cover;
  object-position: 20%;
  position: absolute;
  height:100%;
  width:100%;
  top:0;
  left:0;
  bottom:0;
  right:0;
}

.App-team:after {
  position: absolute;
  content:"";
  height:100%;
  width:100%;
  top:0;
  left:0;
  background:  linear-gradient(
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 1)
  );
}

.App-team-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
  font-weight: bold;
  font-size: calc(10px + 5vmin);
  padding: 20px;
  min-width: 80%;
}
